.highlight-table {
    background-color: #f5f5f5;
  }
  
  .input-highlight {
    background-color: lightgreen;
  }
  
  .result-highlight {
    background-color: lightblue;
  }
  
  .table {
    width: 100%;
    table-layout: fixed;
  }
  
  .table th,
  .table td {
    vertical-align: middle; /* Center content vertically */
    height: 65px; /* Set a fixed height for all cells */
    text-align: center; /* Center content horizontally */
  }
  
  .form-control {
    width: 100%;
    height: 100%;
    box-sizing: border-box; /* Ensure padding is included within the height */
  }
  

  .info-icon {
    margin-left: 5px;
    cursor: pointer;
    color: #007bff;
  }

  /* .disply-together {
    display: flex;
  }


  .disply-together .info-icon-block {
    padding-top: 8px;
  } */

  .input-wrapper {
    display: flex;
    align-items: center;
  }
  
  .input-wrapper .form-control {
    flex: 1;
    margin-right: 5px;
  }
  
  .info-icon-block {
    display: flex;
    align-items: center;
  }
  